
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
import MapZone from "./MapZone.vue";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MapZone,
  },
  data() {
    return {
      district_id: null,
      AreasDistrictAvailableDays: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchAreasDistrictAvailableDays: null,
      AreasDistrictAvailableDaysSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addAreasDistrictAvailableDays() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addAreasDistrictAvailableDays",
        inputs: [
          {
            model: "district_id",
            type: "text",
            label: "areas_district_available_days.district_id",
          },
          {
            model: "available_day",
            type: "text",
            label: "areas_district_available_days.available_day",
          },
          {
            model: "created_at",
            type: "text",
            label: "areas_district_available_days.created_at",
          },
          {
            model: "updated_at",
            type: "text",
            label: "areas_district_available_days.updated_at",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("areas-district-available-days", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editAreasDistrictAvailableDays(app) {
      var data = {
        title: "popups.areas_district_available_days",
        inputs: [
          {
            model: "district_id",
            type: "text",
            label: "areas_district_available_days.district_id",
            value: app.district_id,
          },
          {
            model: "available_day",
            type: "text",
            label: "areas_district_available_days.available_day",
            value: app.available_day,
          },
          {
            model: "created_at",
            type: "text",
            label: "areas_district_available_days.created_at",
            value: app.created_at,
          },
          {
            model: "updated_at",
            type: "text",
            label: "areas_district_available_days.updated_at",
            value: app.updated_at,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .put("areas-district-available-days", app.id, obj)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateAreasDistrictAvailableDays(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "district_id",
            type: "text",
            label: "areas_district_available_days.district_id",
          },
          {
            model: "available_day",
            type: "text",
            label: "areas_district_available_days.available_day",
          },
          {
            model: "created_at",
            type: "text",
            label: "areas_district_available_days.created_at",
          },
          {
            model: "updated_at",
            type: "text",
            label: "areas_district_available_days.updated_at",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("areas-district-available-days", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.AreasDistrictAvailableDaysSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("areas-district-available-days/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.AreasDistrictAvailableDays = res.data;
          console.log("######## files", this.AreasDistrictAvailableDays);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.AreasDistrictAvailableDaysSearchMode = false;
      this.get(this.page);
    },

    getAreasDistrictAvailableDays() {
      this.http.get("areas-district-available-days").then((res) => {
        this.AreasDistrictAvailableDays = res.data;
      });
    },
    deleteAreasDistrictAvailableDays(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("areas-district-available-days", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      this.district_id = this.$route.params.id;
      console.log(page);
      this.http
        .post("areas-district-available-days/paginate", {
          limit: this.limit,
          district_id: this.district_id,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.AreasDistrictAvailableDays = res.data;
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.areas_district_available_days.text')"
    />
    <map-zone />
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




