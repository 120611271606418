<template>
  <div>
    <l-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 400px; width: 100%"
      @click="addMarker"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker v-for="(marker, index) in zone" :key="index" :lat-lng="marker">
        <l-tooltip :options="{ permanent: true, interactive: true }">
          <div>
            {{ `Point ${index + 1}` }}
          </div>
        </l-tooltip>
        <l-icon>
          <img src="@/assets/images/leaflet/marker-icon.png" />
        </l-icon>
      </l-marker>
    </l-map>
    <button
      class="btn btn-success mx-2 mt-2"
      @click="saveZone"
      :disabled="zone.length !== 4"
    >
      <i class="fa fa-save"></i>
    </button>
    <button class="btn btn-dark mx-2 mt-2" @click="clearZone">
      <i class="fa fa-trash"></i>
    </button>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon,
} from "@vue-leaflet/vue-leaflet";
import axios from "axios";

export default {
  props: {
    initialCenter: {
      type: Object,
      default: () => ({ lat: 24.774265, lng: 46.738586 }),
    },
  },
  data() {
    return {
      zoom: 5,
      center: latLng(this.initialCenter.lat, this.initialCenter.lng),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
      },
      zone: [],
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
  },
  methods: {
    addMarker(e) {
      if (this.zone.length < 4) {
        const newPoint = { lat: e.latlng.lat, lng: e.latlng.lng };
        this.zone.push(newPoint);
      } else {
        alert("You have already selected 4 points for the zone.");
      }
    },
    async saveZone() {
      if (this.zone.length === 4) {
        try {
          const response = await axios.post("YOUR_API_ENDPOINT", {
            zone: this.zone,
          });
          console.log(response);
          alert("Zone saved successfully!");
        } catch (error) {
          console.error("There was an error saving the zone!", error);
          alert("Error saving the zone.");
        }
      } else {
        alert("Please select exactly 4 points for the zone.");
      }
    },
    clearZone() {
      this.zone = [];
    },
  },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
